import React from 'react';

const Context = React.createContext({});

export const TYPES = {
  SET_RESULTS: 'SET_RESULTS',
  SET_PARAMS: 'SET_PARAMS',
  RESET: 'RESET',
};

const checkForCachedState = () => {
  const cache = localStorage.getItem('state');
  if (cache) {
    return JSON.parse(cache);
  }
};

const FRESH_STATE = {
  results: {},
  params: {},
};

const INITIAL_STATE = checkForCachedState() || FRESH_STATE;

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          [action.source]: action.payload,
        },
      };
    case TYPES.SET_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case TYPES.RESET:
      return FRESH_STATE;
    default:
      return state;
  }
};

const withLocalStorageCache = (reducer) => {
  return (state, action) => {
    const newState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(newState));
    return newState;
  };
};

export const StateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    withLocalStorageCache(reducer),
    INITIAL_STATE
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Context;
