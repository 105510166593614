import React from 'react';
import { get } from 'lodash';
import { useAsyncFn, useEffectOnce } from 'react-use';
import useGlobalState from '../hooks/use-global-state';
import {
  ResponsiveTable,
  Flex,
  Button,
} from '@actovos-consulting-group/ui-core';
import axios from '../utils/axios';
import { TYPES } from '../context';

const COLUMNS = [
  {
    Header: 'Record',
    accessor: 'label',
  },
  {
    Header: 'Details',
    accessor: 'link',
    Cell: ({ cell: { value } }) => (
      <a rel="noopener noreferrer" href={value} target="_blank">
        <Button onClick={() => null}>Details</Button>
      </a>
    ),
  },
];

const SearchResults = ({ endpoint, source }) => {
  const { state, dispatch } = useGlobalState();
  const results = get(state.results, source, []);

  const [{ loading }, handleSearchReq] = useAsyncFn(async (params) => {
    const { data } = await axios.get(endpoint, { params });
    dispatch({ type: TYPES.SET_RESULTS, source, payload: data });
  });

  useEffectOnce(() => {
    if (!results.length) {
      handleSearchReq(state.params);
    }
  });

  return (
    <Flex flexDirection="column" flex={1}>
      <p>
        Showing results for {state.params.firstName} {state.params.lastName}
      </p>
      <h2>{source}</h2>
      <ResponsiveTable loading={loading} columns={COLUMNS} data={results} />
    </Flex>
  );
};

export default SearchResults;
