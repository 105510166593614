import React from 'react';
import {
  Button,
  Input,
  Grid,
  Field,
  Flex,
} from '@actovos-consulting-group/ui-core';
import { Form, Field as RField } from 'react-final-form';
import useGlobalState from '../hooks/use-global-state';
import { TYPES } from '../context';

const SearchForm = () => {
  const { dispatch, state } = useGlobalState();

  return (
    <Flex>
      <Form
        initialValues={state.params}
        onSubmit={(data) => {
          dispatch({ type: TYPES.SET_PARAMS, payload: data });
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              minHeight: '100%',
            }}
          >
            <Flex>
              <Grid.Row>
                <Grid.Column size={{ _: 12, xs: 4 }}>
                  <Field>
                    <RField name="firstName">
                      {({ input, meta: { error, touched } }) => (
                        <Input
                          {...input}
                          label="First Name"
                          name="firstName"
                          error={touched && error}
                        />
                      )}
                    </RField>
                  </Field>
                </Grid.Column>
                <Grid.Column size={{ _: 12, xs: 4 }}>
                  <Field>
                    <RField name="lastName">
                      {({ input, meta: { error, touched } }) => (
                        <Input
                          {...input}
                          label="Last Name"
                          name="lastName"
                          error={touched && error}
                        />
                      )}
                    </RField>
                  </Field>
                </Grid.Column>
                <Grid.Column size={{ _: 12, xs: 4 }}>
                  <Field>
                    <RField name="dob">
                      {({ input, meta: { error, touched } }) => (
                        <Input
                          {...input}
                          label="Date of Birth"
                          name="dob"
                          error={touched && error}
                        />
                      )}
                    </RField>
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Button
                style={{ height: 42, marginLeft: 20 }}
                leftIcon="FaRocket"
                variant="success"
                type="submit"
              >
                Search
              </Button>
            </Flex>
          </form>
        )}
      </Form>
    </Flex>
  );
};

export default SearchForm;
