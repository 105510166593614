import React from 'react';
import ReactDOM from 'react-dom';
import Global from './global';
import 'react-tabs/style/react-tabs.css';

ReactDOM.render(
  <React.StrictMode>
    <Global />
  </React.StrictMode>,
  document.getElementById('root')
);
