import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Flex, Button, Block } from '@actovos-consulting-group/ui-core';
import SearchForm from './components/search-form';
import useGlobalState from './hooks/use-global-state';
import { TYPES } from './context';
import SearchResults from './components/search-results';
import Logo from './components/logo';
import Header from './components/header';

const SEARCH_SECTIONS = [
  {
    id: 1,
    source: 'OKC Traffic',
    endpoint: '/okc-traffic',
  },
  {
    id: 2,
    source: 'OSCN',
    endpoint: '/oscn',
  },
];

const App = () => {
  const { state, dispatch } = useGlobalState();

  return Object.keys(state.params).length ? (
    <Flex flexDirection="column" flex={1}>
      <Header />
      <Block m={20}>
        <Button
          variant="outline"
          onClick={() => {
            dispatch({ type: TYPES.RESET });
          }}
        >
          Clear Results
        </Button>
      </Block>
      <Flex p={20} flex={1}>
        <Tabs style={{ width: '100%' }}>
          <TabList>
            {SEARCH_SECTIONS.map((sec) => (
              <Tab key={sec.id}>{sec.source}</Tab>
            ))}
          </TabList>

          {SEARCH_SECTIONS.map((sec) => (
            <TabPanel key={sec.id}>
              <SearchResults {...sec} />
            </TabPanel>
          ))}
        </Tabs>
      </Flex>
    </Flex>
  ) : (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width={600}
    >
      <Logo />
      <SearchForm />
    </Flex>
  );
};

export default App;
