import React from 'react';
import { Flex, Provider } from '@actovos-consulting-group/ui-core';
import { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { StateProvider } from './context';
import App from './app';

const GlobalStyle = createGlobalStyle(
  () => css`
    ${styledNormalize}

    #root, html, body {
      height: 100%;
    }
  `
);

export default () => {
  return (
    <StateProvider>
      <Provider>
        <Flex flex={1} height="100%">
          <App />
        </Flex>
        <GlobalStyle />
      </Provider>
    </StateProvider>
  );
};
